import React from "react"
import {Helmet} from "react-helmet"
import {NotificationContainer} from "../Notification";
import './layout.css'

class Layout extends React.Component {
    render() {
        const {children} = this.props

        return (
            <>
                <Helmet title="4Elements Respondent App">
                    <html lang="en"/>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                    />
                    <link rel="preconnect" href="https://fonts.gstatic.com"/>
                    <link href="https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;700&display=swap" rel="stylesheet"/>
                </Helmet>
                <NotificationContainer/>
                {children}
            </>
        )
    }
}

export default Layout
