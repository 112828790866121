import React from 'react';
import PropTypes from 'prop-types';

class Notification extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['primary', 'link', 'info', 'success', 'warning', 'danger']),
    message: PropTypes.node,
    timeOut: PropTypes.number,
    onClick: PropTypes.func,
    onRequestHide: PropTypes.func
  };

  static defaultProps = {
    type: 'info',
    message: null,
    timeOut: 5000,
    onClick: () => {
    },
    onRequestHide: () => {
    }
  };

  componentDidMount = () => {
    const { timeOut } = this.props;
    if (timeOut !== 0) {
      this.timer = setTimeout(this.requestHide, timeOut);
    }
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
    this.requestHide();
  };

  requestHide = () => {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide();
    }
  };

  render() {
    const { type, message } = this.props;
    const classes = ['notification', `is-${type}`].join(' ');
    return (
      <div className={classes} onClick={this.handleClick} aria-hidden="true">
        {message}
      </div>
    );
  }
}

export default Notification;
